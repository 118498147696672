<template>
  <div>
    <div
      class="custom-content"
      v-if="json"
      v-html="renderHtmlFromEditorJS(json)"
      v-bind="$attrs"
    ></div>
    <div
      class="custom-content"
      v-else
      v-html="sanitize(html)"
      v-bind="$attrs"
    ></div>
  </div>
</template>

<script lang="ts">
import { sanitize, renderHtmlFromEditorJS } from '@/utils/utils';

export default {
  name: 'HMCustomContent',
  props: {
    html: {
      type: String,
    },
    json: {
      type: String,
    },
  },
  methods: {
    renderHtmlFromEditorJS: renderHtmlFromEditorJS,
    sanitize,
  },
};
</script>

<style lang="scss" scoped></style>
